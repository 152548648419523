<template>
  <div>
    <!-- <el-card class="box-card"> -->
    <!-- <div style="margin-bottom: 10px"><i class="card-line"></i>优秀研报</div> -->
    <!-- 外汇table切换暂时不用 -->

    <div v-if="[1, 2, 3, 5, 4].indexOf(courseType) === -1">
      <el-radio-group v-model="financeRadio" :disabled="disabled" size="default" @change="financeRadioChange"
        style="margin:0 -20px;" text-color="#3B6EFB" fill="#FFFF">
        <el-radio-button style="width: 50px;font-size: 20px;" label="证券" value="证券" />
        <el-radio-button style="width: 50px;font-size: 20px;" label="外汇" value="外汇" />
      </el-radio-group>
    </div>
    <div style="margin: 10px 0">
      <span class="report-title" style="margin-left: 0;">报告类型</span>
      <el-select v-model="reportValue" placeholder="请选择" size="mini" clearable style="width: 120px;">
        <el-option v-for="item in reportOptions" :key="item.filetype" :label="item.type" :value="item.filetype">
        </el-option>
      </el-select>
      <span class="report-title">小组名称</span>
      <el-select v-model="groupValue" placeholder="请选择" size="mini" clearable style="width: 120px;">
        <el-option v-for="item in groupOption" :key="item.acId" :label="item.groupName" :value="item.acId">
        </el-option>
      </el-select>
      <span class="report-title">报告名称</span>
      <el-input v-model="fileName" placeholder="请输入名称" size="mini" style="width: 120px"></el-input>

      <span class="report-title">上传时间</span>
      <el-date-picker size="mini" style="width: 230px;" v-model="datePicker" type="daterange" @change="DateChange"
        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
      </el-date-picker>
      <span class="report-title">是否优秀</span>
      <el-select v-model="goods" placeholder="请选择" size="mini" clearable style="width: 120px">
        <el-option label="是" :value="true"></el-option>
        <el-option label="否" :value="false"></el-option>
      </el-select>
      <!-- <el-switch style="margin: 0 5px" v-model="goods" inline-prompt active-text="优秀" inactive-text="默认"></el-switch> -->

      <el-button type="primary" style="margin-left: 10px;float: right;" size="mini" @click="getDrafrList"
        icon="el-icon-search">查询</el-button>
    </div>
    <spread-table v-loading="loading" :tableHeight="tableHeight" :operation="operation" :pageSize="pageSize"
      :currentPage="currentPage" :total="total" @sizeChange="sizeChange" @currentChange="currentChange"
      @clickButton="clickButton" :tableColumn="startList" :tableData="spreadTable"></spread-table>
    <!-- </el-card> -->

    <el-dialog class="box-card" :model-value="showHtml" style="margin-top: 20px">
      <wang-editor :value="reportHtml" :disable="true"></wang-editor>
      <el-button style="margin: 15px 0" type="primary" size="mini" @click="showHtml = false">关闭</el-button>
    </el-dialog>

    <!-- <el-card class="box-card" style="margin-top: 30px"> -->
    <div style="margin-bottom: 10px"><i class="card-line"></i>双周报提交情况</div>
    <div style="margin: 10px 0">
      <div style="text-align: right">
        <span class="report-title">周期</span>
        <el-select v-model="weekValue" placeholder="请选择" size="mini" @change="weekChange" clearable>
          <el-option v-for="(item, index) in weekList" :key="item" :label="item" :value="item">
          </el-option>
        </el-select>
      </div>
    </div>
    <spread-table v-loading="loadingEs" :tableHeight="tableHeight" :pageSize="pageSizeEs" :currentPage="currentPageEs"
      :total="totalEs" @sizeChange="sizeChangeEs" @currentChange="currentChangeEs" :tableColumn="startListEs"
      :tableData="spreadTableEs.slice((currentPageEs - 1) * pageSizeEs, currentPageEs * pageSizeEs)">

    </spread-table>
    <!-- </el-card> -->
  </div>
</template>

<script>
import { defineComponent, reactive, toRefs, onMounted } from "vue";
import SpreadTable from '@/components/SpreadTable'
import { fineData } from "./fineData";
import WangEditor from '@/components/WangEnduitTry/index.vue';

import {
  clickButtons, currentChanges,
  sizeChanges, getDrafrLists, DateChanges, getNoReLists, sizeChangesEs, currentChangesEs, getUserLists, weekChanges
} from "./fineMethods";
import { useRouter } from "vue-router";
import { getTypes } from "../../course-manage/upreport-manage/UprePort-Edit/reportMethods";
import { getGroupLists } from "../../course-manage/upreport-manage/Upreport-Browser/browserMethods";
export default defineComponent({
  name: "index",
  components: {
    SpreadTable,
    WangEditor,
  },
  setup() {
    let router = useRouter();
    onMounted(() => {
      data.courseType = JSON.parse(sessionStorage.getItem('courseType'))
      getUserLists(data)
      getDrafrList()
      getNoReList()
      getGroupList()
      getType(1)
    })
    let data = reactive({
      courseType: 0,
      financeRadio: '证券',
      weekValue: '',
      weekList: [],
      reportOptions: [],
      goods: undefined,
      disabled: false,
      startList: [
        { name: '周期', text: 'currentWeek' },
        { name: '小组名称', text: 'groupName' },
        { name: '报告类型', text: 'fileTypeName' },
        { name: '报告名称', text: 'name' },
        { name: '是否优秀', text: 'goodTypes' },
        { name: '报告状态', text: 'fileStateName' },
        { name: '报告上传者', text: 'userName' },
        { name: '上传时间', text: 'date' },
        { name: '上传状态', text: 'whetherState' },
      ],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      loading: true,
      spreadTable: [],
      //  未提交情况
      startListEs: [
        { name: '当前周', text: 'currentWeek' },
        { name: 'ACID', text: 'acid' },
        { name: '小组名称', text: 'groupName' },
        { name: '提交状况', text: 'fileStatus' },
      ],
      currentPageEs: 1,
      pageSizeEs: 10,
      totalEs: 0,
      loadingEs: true,
      spreadTableEs: [],
      tableHeight: 400,
      operation: ['查看', '评为优秀'],
      pageType: 1,
      lastState: 1000,
      filetype: null,

      reportValue: undefined,
      fileName: undefined,
      datePicker: undefined,
      startTime: undefined,
      endTime: undefined,

      showHtml: false,
      reportHtml: '',
      condition: '',
      groupOption: [],
      groupValue: ''
    })
    let financeRadioChange = () => {
      data.disabled = true
      getDrafrList()
      weekChange(data.weekValue)
    }
    let getType = (num) => { getTypes(num, data) }
    let getDrafrList = () => { getDrafrLists(data) }
    let getGroupList = () => { getGroupLists(data) }
    let sizeChange = (val) => { sizeChanges(val, data) }
    let currentChange = (val) => { currentChanges(val, data) }
    let sizeChangeEs = (val) => { sizeChangesEs(val, data) }
    let currentChangeEs = (val) => { currentChangesEs(val, data) }
    let DateChange = (val) => { DateChanges(val, data) }
    let clickButton = (val) => { clickButtons(val, data, router) }
    let getNoReList = () => { getNoReLists(data) }
    let weekChange = (val) => { weekChanges(val, data) }
    return {
      ...toRefs(data),
      getDrafrList,
      sizeChange,
      currentChange,
      currentChangeEs,
      sizeChangeEs,
      DateChange,
      clickButton,
      weekChange,
      financeRadioChange
    }
  }
})
</script>

<style scoped lang="less">
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #3B6EFB;
}

.report-title {
  margin-left: 10px;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}

::v-deep.el-radio-button {
  .el-radio-button__inner {
    background: #FFFF;
    color: #333;
    border: 0 !important;
  }
}
</style>
